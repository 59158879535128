<template>
  <li
    class="hidden md:block"
    v-if="deliveryMode == 'delivery'"
  >
    <div
        @click="onAddressSelect"
      class="inline-flex gap-2 cursor-pointer select-none items-center rounded-full border-1 pl-3 border-black-300 leading-none text-xs h-10"
    >
    <Icon.MapPin :size="15" />
      <p class="line-clamp-1 w-52 py-2 m-auto" v-if="address == null || address.name == ''">
          Digite aqui sua morada
      </p>
      <p class="line-clamp-1 w-52 py-2 m-auto line-clamp-1" v-else>
          {{ address.name }}, {{ address.address_number }} {{ address.zipcode }}
      </p>
    </div>
  </li>
  <li>
    <div
      class="inline-flex rounded-full border-1 border-gray-300 bg-gray-200 shadow-md leading-none text-xs h-10"
    >
      <a
        @click="swichDelivery()"
        :class="
          deliveryMode == 'delivery'
            ? 'pointer-events-none bg-white select-none'
            : 'cursor-pointer'
        "
        class="btn leading-7 rounded-full inline-flex items-center px-4 py-2 text-blue-400 transition-colors duration-300 ease-in focus:outline-none focus:text-blue-400 hover:text-blue-400"
      >
        <Icon.Bike :size="15" />
        <span class="line-clamp-1">Delivery</span>
      </a>
      <a
        @click="swichDelivery()"
        :class="
          deliveryMode == 'takeaway'
            ? 'pointer-events-none bg-white select-none'
            : 'cursor-pointer'
        "
        class="btn leading-7 inline-flex rounded-full items-center px-4 py-2 text-gray-500 transition-colors duration-300 ease-in focus:outline-none focus:text-blue-400 hover:text-blue-400"
      >
        <Icon.Store :size="15" />
        <span class="line-clamp-1"
          >Take&nbsp;away</span
        >
      </a>
    </div>
  </li>







  <div
    v-if="popupOpened"
    class="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none"
    id="modal-id"
  >
    <div class="absolute bg-black bg-opacity-80 inset-0 z-0"></div>
    <div
      class="w-full max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg bg-white"
    >
      <!--content-->
      <div>
        <Icon.X
          @click="closePopup()"
          class="cursor-pointer absolute top-1 right-1"
        />
        <!--body-->
        <div v-if="!loading" class="text-center p-5 flex-auto justify-center">
          <h2 class="text-xl font-bold py-4">Morada de entrega</h2>
          <div v-if="error == ''">
          <div class="relative mt-2 rounded-md shadow-sm">
            <div
              class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
            >
              <span class="text-gray-500">
                <Icon.MapPin :size="15" />
              </span>
            </div>
              <input
                type="text"
                @input="updateInternalValue"
                v-model="searchText"
                placeholder="Pesquisar"
                ref="streetRef"
                role="presentation"
                autocomplete="off"
                class="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
          </div>
          <div v-if="!confirmMode && searchText == ''" class="mt-4">
            Se deseja realizar um pedido takeAway, <a href="#" @click.stop="goToTakeAway" class="text-red-400">clique aqui</a>!
          </div>
          <div v-if="confirmMode" class="grid grid-cols-1 gap-2">
            <div class="flex gap-2 hover:bg-gray-200 rounded-md shadow my-2 cursor-pointer p-2 bg-gray-300">
              <div class="w-10 justify-center items-center my-auto">
                <Icon.MapPin class="m-auto" />
              </div>

              <div class="text-start">
                <div>{{ confirmPlace }}</div>
                <div class="text-xs">
                  {{ confirmPlace2 }}
                </div>
              </div>
            </div>

            <div class="xl:col-span">
              <label
                class="block text-sm font-medium text-default-900 mb-2"
                for="home_header"
                >Número *</label
              >
              <input
                rows="3"
                class="block w-full bg-transparent rounded-3xl py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
                type="text"
                v-model="address_number"
              />
            </div>

            <div class="xl:col-span">
              <label
                class="block text-sm font-medium text-default-900 mb-2"
                for="home_header"
                >Número Andar/apartamento</label
              >
              <input
                rows="3"
                class="block w-full bg-transparent rounded-3xl py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
                type="text"
                v-model="appartment_number"
              />
            </div>

            <div class="xl:col-span">
              <label
                class="block text-sm font-medium text-default-900 mb-2"
                for="home_header"
                >Observações para entrega</label
              >
              <textarea
                id="home_header"
                name="home_header"
                rows="3"
                class="block w-full bg-transparent rounded-3xl py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
                type="text"
                v-model="confirmObs"
              />
            </div>
            
            <div v-if="address_number_error != ''" class="my-2 text-red-700">{{ address_number_error }}</div>
            <div class="flex justify-end mt-2">
              <button
              @click="onAddressSelected2()"
                class="px-6 py-3 rounded-3xl bg-primary text-white capitalize transition-all hover:bg-primary"
              >
                Confirmar
              </button>
            </div>
          </div>
          <div v-if="!historyMode && !confirmMode">
            <div v-if="sugestionsList.length > 0 && searchText != ''">
              <div
                v-for="sugestion in sugestionsList"
                :key="sugestion.place_id"
                class="flex gap-2 hover:bg-gray-200 rounded-md shadow my-2 cursor-pointer p-2"
                @click="onAddressSelected(sugestion)"
              >
                <div class="w-10 justify-center items-center my-auto">
                  <Icon.MapPin class="m-auto" />
                </div>

                <div class="text-start">
                  <div>{{ sugestion.structured_formatting.main_text }}</div>
                  <div class="text-xs">
                    {{ sugestion.structured_formatting.secondary_text }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="sugestionsList.length == 0">
              <div class="mt-6">
                Não encontramos nenhuma morada, tente novamente.
              </div>
            </div>
          </div>
          <div v-if="historyMode && !confirmMode && (addresses?.length ?? 0) > 0">

            <div class="font-bold mt-4">Minhas últimas moradas</div>
            <div
              v-for="address in addresses"
              :key="address.place_id"
              class="flex gap-2 hover:bg-gray-200 rounded-md shadow my-2 cursor-pointer p-2"
              :class="
                address.place_id == currentAddress.place_id ? 'bg-gray-300' : ''
              "
              @click="onAddressHistoricSelected(address.place_id)"
            >
              <div class="w-10 justify-center items-center my-auto">
                <Icon.MapPin class="m-auto" />
              </div>

              <div class="text-start">
                <div>{{ address.address }} {{ address.address_number }}</div>
                <div class="text-xs">
                  {{ address.area_level_3 }} - {{ address.area_level_2 }} -
                  {{ address.area_level_1 }}
                </div>
              </div>
            </div>
          </div></div>
          <div class="grid gap-4 col-1" v-if="error != ''">
            <div
              class="pointer-events-none inset-y-0 left-0 items-center pl-3"
            >
              <span class="text-gray-500">
                <Icon.alert :size="15" />
              </span>
              {{ error }}
            </div>
            <div class="">
              <button
              @click="reset"
                class="px-6 py-3 rounded-3xl bg-primary text-white capitalize transition-all hover:bg-primary"
              >
                Adicionar outra morada
              </button>
            </div>
          </div>
        </div>

        <Loading :enabled="loading" />
      </div>
    </div>
  </div>
</template>

<script setup>
import * as Icon from "lucide-vue-next";
import _debounce from "lodash/debounce";
import Loading from "./Loading.vue";
</script>

<script>
export default {
  props: {
    currentCart: { type: Object },
    currentAddress: { type: Object },
    addresses: { type: Array },
  },
  data() {
    return {
      loading: false,
      error: "",
      searchText: "",
      addressName: "",
      lat: 0,
      lng: 0,
      deliveryMode: "delivery",
      address: "",
      sugestionsList: [],
      total: 0,
      popupOpened: false,
      historyMode: true,
      confirmMode: false,
      confirmPlace: null,
      confirmPlace2: null,
      confirmPlaceId: 0,
      confirmObs: "",
      confirmObsDefault: "Encontro na porta",
      address_number: "",
      appartment_number: "",
      address_number_error: '',
    };
  },
  mounted() {
    this.emitter.on("cart.updated", this.onCartUpdated);
    this.emitter.on("address.select", this.onAddressSelect);

    this.deliveryMode = this.currentCart.deliveryMode ?? "delivery";
    this.address = this.currentAddress;
    this.checkAddress();
  },
  created() {},
  methods: {
    onCartUpdated(data) {
      this.deliveryMode = data.deliveryMode;
      this.address = data.currentAddress;
      
      this.checkAddress();
    },
    onAddressInput(search) {
      const self = this;
      if(search.length <= 5) return;
      this.historyMode = false;
      this.confirmMode = false;
      this.confirmObs = this.confirmObsDefault;
      this.address_number = "";
      this.appartment_number = "";
      this.address_number_error = '';

      axios
        .post("/addressSearch", { search: search })
        .then((response) => {
          this.sugestionsList = response.data.predictions ?? [];
        })
        .catch((error) => {
          this.sugestionsList = [];
          console.log(error);
        });
    },
    updateInternalValue(event) {
      this.touched = true;
      this.updateValue(event.target.value);
    },
    updateValue: _debounce(function (value) {
      this.touched = false;
      this.onAddressInput(value);
    }, 600),
    checkAddress() {
      if (this.deliveryMode == "delivery" && this.currentAddress.name == "")
        this.onAddressSelect();
    },
    onAddressSelect() {
      if(this.deliveryMode == "delivery") this.popupOpened = true;
    },
    onAddressSelected(suggestion) {
      this.confirmMode = true;

      this.confirmPlace = suggestion.terms[0]['value'];
      this.address_number = this.checkIsValidNumber(suggestion.terms[1]['value']);
      this.confirmPlace2 = suggestion.structured_formatting.secondary_text;
      this.confirmPlaceId = suggestion.place_id;
    },

    checkIsValidNumber(r) {
      if (Number.isNaN(Number.parseInt(r))) {
        return '';
      }
      return parseFloat(r);
    },

    onAddressHistoricSelected(place_id){
      const self = this;
      self.loading = true;
      axios
        .post("/setCurrentAddressForHistory", {
          placeId: place_id,
        })
        .then((response) => {
          this.reset();
          location.reload();
        })
        .catch((error) => {
          this.sugestionsList = [];
          self.error = error.response?.data?.error ?? "Ocorreu um erro, tente novamente por favor!";
          self.loading = false;
          console.log(error);
        });
    },
    onAddressSelected2() {
      const self = this;
      self.loading = true;
      this.address_number_error = "";

      if(this.address_number == ""){
        this.address_number_error = "Digite o número da morada";
        return;
      }
      
      this.confirmMode = false;

      axios
        .post("/setCurrentAddress", {
          place: this.confirmPlace,
          placeId: this.confirmPlaceId,
          address_number: this.address_number,
          appartment_number: this.appartment_number,
          obs: this.confirmObs,
        })
        .then((response) => {
          this.reset();
          location.reload();
        })
        .catch((error) => {
          self.error = error.response?.data?.error ?? "Ocorreu um erro, tente novamente por favor!";
          self.loading = false;
          console.log(error);
        });
    },
    swichDelivery() {
      const self = this;
      self.loading = true;
      axios
        .post("/switchDelivery")
        .then((response) => {
          location.reload();
          //document.Cart.loadCart();
        })
        .catch((error) => {
          self.loading = false;
          console.log(error);
        });
    },
    goToTakeAway(){
      const self = this;
      self.loading = true;
      this.popupOpened = false;
      axios
        .get("/takeaway")
        .then((response) => {
          location.reload();
        })
        .catch((error) => {
          self.loading = false;
          console.log(error);
        });
    },
    closePopup() {
      this.popupOpened = false;
      this.reset();
    },
    reset() {
      this.searchText = '';
      this.sugestionsList = [];
      this.historyMode = true;
      this.error = '';
      this.confirmObs = this.confirmObsDefault;
    },
  },
};
</script>
