<template>
  <div v-if="enabled" class="w-full flex items-center justify-center p-40">
      <div
        class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
        role="status"
      >
        <span
          class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
          >Loading...</span
        >
      </div>
    </div>
</template>

<script setup>
import * as Icon from "lucide-vue-next";
</script>

<script>
export default {
  props: {
    enabled: { type: Boolean, default: false },
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  created() {},
  methods: {
  },
};
</script>
