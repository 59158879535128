<template>
  <div class="grid grid-col-1 gap-4">
    <div class="">
      <div class="grid md:grid-cols-2 grid-cols-1 gap-4">
        <div class="">
          <label class="block text-sm font-medium text-default-900 mb-2">Restaurante</label>
          <select v-model="currentStore" @input="updateStore($event.target.value)"
              class="block w-full bg-transparent text-sm rounded-3xl border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50">
              <option value="*" >Todas ({{ stores.length }})</option>
              <option v-for="store in stores" :key="store.id" :value="store.id" >{{ store.name }}</option>
          </select>
        </div>
        <div class="">
          <label class="block text-sm font-medium text-default-900 mb-2">Data</label>
          <input type="date" v-model="currentDate" @input="updateDate($event.target.value)"
          class="block w-full bg-transparent text-sm rounded-3xl border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50" />
        </div>
      </div>
    </div>

    <Loading :enabled="loading" />

    <div v-if="!loading">
      <h2 class="text-lg">Novos ({{ orders_new.length }})</h2>
      <div class="flex flex-wrap gap-4">
        <div v-for="order in orders_new" :key="order.id" class="shadow-soft-xl w-40">
          <card v-if="order.type == 'delivery'" :data="order" color="#1654f4" textcolor="white" class="cursor-pointer w-full" @click="showOrder(order)" />
          <card v-else :data="order" color="#7ba0ff" textcolor="white" class="cursor-pointer w-full" @click="showOrder(order)" />
        </div>
      </div>

      <h2 class="text-lg">Prontos ({{ orders_ready.length }})</h2>
      <div class="flex flex-wrap gap-4">
        <div v-for="order in orders_ready" :key="order.id" class="shadow-soft-xl w-40">
          <card v-if="order.type == 'delivery'" :data="order" color="#466D1D" textcolor="white" class="cursor-pointer w-full" @click="showOrder(order)" />
          <card v-else :data="order" color="#466D1D" textcolor="white" class="cursor-pointer w-full" @click="showOrder(order)" />
        </div>
      </div>


      <h2 class="text-lg">Aceitos ({{ orders_accepted.length }})</h2>
      <div class="flex flex-wrap gap-4">    
        <div v-for="order in orders_accepted" :key="order.id" class="shadow-soft-xl w-40">
          <card :data="order" color="#86efac" class="cursor-pointer w-full" @click="showOrder(order)" />
        </div>
      </div>


      <h2 class="text-lg">Em entrega ({{ orders_delivering.length }})</h2>
      <div class="flex flex-wrap gap-4">    
        <div v-for="order in orders_delivering" :key="order.id" class="shadow-soft-xl w-40">
          <card :data="order" color="#ffcc00" class="cursor-pointer w-full" @click="showOrder(order)" />
        </div>
      </div>

      
      <h2 class="text-lg">Finalizados ({{ orders_done.length }})</h2>
        <div class="flex flex-wrap gap-4">
        <div v-for="order in orders_done" :key="order.id" class="shadow-soft-xl w-40">
          <card :data="order" color="white" class="cursor-pointer w-full" @click="showOrder(order)" />
        </div>
      </div>
    </div>
  </div>




  <div
    v-if="orderModalOpened"
    class="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none"
    id="modal-product-addon"
  >
    <div class="absolute bg-black bg-opacity-80 inset-0 z-0"></div>
    <div
      class="relative mx-auto my-auto rounded-xl shadow-lg bg-white w-9/12 h-4/5"
    >
      <!--content-->
      <div class="w-full h-full relative flex flex-col">
        <div>
        <Icon.X
          @click="closeOrder"
          class="cursor-pointer absolute top-1 right-1 z-20"
        />
        <!--body-->
        <div class="absolute z-10 w-full top-0 bg-red">
          <h2 class="text-center text-lg text-black font-black pt-4">Pedido #{{ order.code }}</h2>
        </div>
      </div>
      
      <div id="receipt_print" class="hidden print:block mb-4">
        <div class="grid grid-cols-1 gap-2">
          <div class="relative">
            <h2 class="flex-auto text-black font-black">Pedido #{{ order.code }}</h2>
            <integrator-icon :integrator="order.integrator" class="w-8 flex-none absolute top-0 right-0" />
          </div>

          <div>
            <div class="capitalize"><strong>Tipo:</strong> {{ order.type }}</div>
            <div><strong>Data:</strong> {{ moment(order.created_at).format('DD/MM/YYYY HH:mm') }}</div>
            <div v-if="order.estimated_delivery_at"><strong>Previsão de entrega:</strong> {{ moment(order.estimated_delivery_at).format('HH:mm') }}</div>
            <div v-if="order.delivered_time"><strong>Entregue:</strong> {{ moment(order.delivered_time).format('HH:mm') }}</div>
            <div><strong>Valor do Pedido:</strong> {{ toCurrency(order.price_with_discount) }}</div>
          </div>

          <div>
            <div class="bg-slate-200 font-black text-left">Cliente</div>
            <div>
              <div><strong>Nome:</strong> {{ order.eater_name }}</div>
              <div><strong>Telemóvel:</strong> {{ order.eater_phone }}</div>
              <div v-if="order.nif != ''"><strong>NIF:</strong> {{ order.eater_nif }}</div>
              <div v-if="order.eater_address"><strong>Morada:</strong> {{order.eater_address}}, {{ order.eater_address_number}} {{ order.eater_appartment_number}} - {{ order.eater_zipcode}}</div>
            </div>
          </div>

          <div v-if="order.obs != null && order.obs != ''">
            <div class="bg-slate-200 font-black text-left">Observação</div>
            <div>{{ order.obs }}</div>
          </div>
          
          <div>
            <div class="bg-slate-200 font-black text-left">Itens</div>
            <div class="divide-y divide-dashed divide-black">
              <div v-for="item in order.items" :key="item.id">
                <div>
                  <div class="text-left">
                    <strong><span v-if="item.quantity">{{ item.quantity }} x </span>{{ item.name }}</strong> ({{ toCurrency(item.price) }})
                  </div>
                  <div v-if="item.addons" class="">
                    {{ item.addons }}
                  </div>
                  <div v-if="item.obs" class="">
                    <strong>Obs:</strong> {{ item.obs }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-12 overflow-y-auto grow ">
        <div class="grid grid-cols-1 gap-4">
          <div class="p-4">            
            <div class="flex"><integrator-icon :integrator="order.integrator" class="w-10 my-2" /></div>
            <div class="capitalize"><strong>Tipo:</strong> {{ order.type }}</div>
            <div><strong>Recebido:</strong> {{ moment(order.created_at).format('HH:mm') }}</div>
            <div v-if="order.estimated_delivery_at"><strong>Previsão de entrega:</strong> {{ moment(order.estimated_delivery_at).format('HH:mm') }}</div>
            <div v-if="order.delivered_time"><strong>Entregue:</strong> {{ moment(order.delivered_time).format('HH:mm') }}</div>
            <div><strong>Valor do Pedido:</strong> {{ toCurrency(order.price_with_discount) }}</div>
          </div>

          <div>
            <div class="bg-slate-200 p-2 font-black text-left">Cliente</div>
            <div class="p-4">
              <div><strong>Nome:</strong> {{ order.eater_name }}</div>
              <div><strong>Telemóvel:</strong> {{ order.eater_phone }}</div>
              <div v-if="order.nif != ''"><strong>NIF:</strong> {{ order.eater_nif }}</div>
              <div v-if="order.eater_address"><strong>Morada:</strong> {{order.eater_address}}, {{ order.eater_address_number}} {{ order.eater_appartment_number}} - {{ order.eater_zipcode}}</div>
            </div>
          </div>

          <div v-if="order.obs != null && order.obs != ''">
            <div class="bg-slate-200 p-2 font-black text-left">Observação</div>
            <div class="p-4">
              <div>{{ order.obs }}</div>
            </div>
          </div>

          <div>
            <div class="bg-slate-200 p-2 font-black text-left">Itens</div>
            <div class="p-4">
              <div v-for="item in order.items" :key="item.id">
                <div class="my-2">
                  <div class="text-left">
                    <strong><span v-if="item.quantity && item.name != 'Delivery'">{{ item.quantity }} x </span>{{ item.name }}</strong> ({{ toCurrency(item.price * item.quantity) }})
                  </div>
                  <div v-if="item.addons" class="">
                    {{ item.addons }}
                  </div>
                  <div v-if="item.obs" class="">
                    <strong>Obs:</strong> {{ item.obs }}
                  </div>
                </div>
              </div>
            </div>          
          </div>

          <div v-if="order.type == 'delivery' && order.driver != null && order.driver.name != null" class="print:hidden">
            <div class="bg-slate-200 p-2 font-black text-left">Estafeta</div>
            <div class="p-4">
              <div class="text-left">{{ order.driver.name }}</div>
            </div>              
          </div>
        </div>
      </div>     

      <div class="flex justify-end gap-2 print:hidden p-2">          
          <button v-print="printOrder" class="px-6 py-3 rounded-3xl bg-primary text-white capitalize transition-all hover:bg-primary">
            Imprimir
          </button>
          
          <button v-if="(order.status == 'NEW' || order.status == 'ACCEPTED')" @click="makeReady(order)" class="px-6 py-3 rounded-3xl bg-primary text-white capitalize transition-all hover:bg-primary">
            Pronto
          </button>
          
          <button v-if="order.type == 'takeaway' && (order.status == 'NEW' || order.status == 'READY')" @click="makeDelivered(order)" class="px-6 py-3 rounded-3xl bg-primary text-white capitalize transition-all hover:bg-primary">
            Entregue
          </button>
        </div>   
      </div>
    </div>
  </div>



</template>

<script setup>
import * as Icon from "lucide-vue-next";
import moment from 'moment';
import OrderCard from "./OrderCard.vue";
import Loading from "../Loading.vue";
</script>

<script>
export default {
  components: {
    'card': OrderCard,
  },
  props: {
    stores: { type: Array },
  },
  data() {
    return {
      loading: true,
      currentStore: "",
      currentDate: moment().format('YYYY-MM-DD'),
      orders: [],
      orders_new: [],
      orders_ready: [],
      orders_accepted: [],
      orders_delivering: [],
      orders_done: [],
      order: {},
      orderModalOpened: false,
      printOrder: {
              id: "receipt_print",
              popTitle: 'Pedido #',
             beforeOpenCallback (vue) {
                //vue.printLoading = true
                //console.log('打开之前')
              },
              openCallback (vue) {
                //vue.printLoading = false
                //console.log('执行了打印')
              },
              closeCallback (vue) {
                //console.log('关闭了打印工具')
              }
            }
    };
  },
  mounted() {
    this.currentStore = '*';
    this.loadData();
  },
  methods: {
    loadData() {
      const self = this;
      this.loading = true;

      axios
        .post("orders/by_date", {'store': this.currentStore, 'date': this.currentDate})
        .then((response) => {
          self.orders = response.data.orders;

          self.orders_new = self.orders.filter((order) => order.status == 'NEW');
          self.orders_ready = self.orders.filter((order) => order.status == 'READY');
          self.orders_accepted = self.orders.filter((order) => order.status == 'ACCEPTED');
          self.orders_delivering = self.orders.filter((order) => order.status == 'ON_DELIVERY');
          self.orders_done = self.orders.filter((order) => order.status == 'DELIVERED');
        })
        .catch((error) => {
          self.orders = [];
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    makeReady(order){
      const self = this;
      this.loading = true;

      axios
        .post("orders/set_ready", {'orderid': order.id})
        .then((response) => {
        })
        .catch((error) => {
          self.orders = [];
          console.log(error);
        })
        .finally(() => {
          self.loading = false;
          self.orderModalOpened = false;
          self.loadData();
        });
    },

    makeDelivered(order){
      const self = this;
      this.loading = true;

      axios
        .post("orders/set_delivered", {'orderid': order.id})
        .then((response) => {
        })
        .catch((error) => {
          self.orders = [];
          console.log(error);
        })
        .finally(() => {
          self.loading = false;
          self.orderModalOpened = false;
          self.loadData();
        });
    },

    sendToZonesoft(order){
      const self = this;
      this.loading = true;

      axios
        .post("/admin/orders/send_to_zonesoft", {'orderid': order.id})
        .then((response) => {
        })
        .catch((error) => {
          alert(error);
        })
        .finally(() => {
          self.loading = false;
          alert('send ok');
        });
    },

    showOrder(order){
      console.log('order', order);
      this.order = order;
      this.orderModalOpened = true;
    },

    closeOrder(){
      this.orderModalOpened = false;
      this.order = {};
    },

    updateStore(store){
      this.currentStore = store;
      this.loadData();
    },

    updateDate(date){
      this.currentDate = moment(date).format('YYYY-MM-DD');
      this.loadData();
    },

    toCurrency(value) {
      value = parseFloat(value, 2);
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("pt-PT", {
        style: "currency",
        currency: "EUR",
      });

      return formatter.format(value);
    },
  },
};
</script>
