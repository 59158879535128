<template>

  <div class="p-2 shadow rounded-2xl bg-clip-border break-words grid grid-cols-1 relative" :style="{ backgroundColor: color, color: textcolor }">
    <integrator-icon :integrator="data.integrator" class="w-8 absolute top-1 end-1" />
    <div class="mb-0 font-sans font-semibold leading-normal text-center">{{data.store.name}}</div>
    <div class="mb-0 font-sans font-semibold leading-normal text-center">#{{data.code}}</div>
    <div class="mb-0 font-sans leading-normal text-sm text-center line-clamp-1">{{data.eater_name}}</div>
    <div class="text-center">{{data.type}}</div>
    
    <div class="text-center" v-if="data.type == 'takeaway'">{{ moment(data.created_at).format('HH:mm') }}<span v-if="data.estimated_ready_for_pickup_at">->{{ moment(data.estimated_ready_for_pickup_at).format('HH:mm') }}</span></div>
    <div class="text-center" v-else>{{ moment(data.created_at).format('HH:mm') }}<span v-if="data.estimated_delivery_at">->{{ moment(data.estimated_delivery_at).format('HH:mm') }}</span></div>
  </div>


</template>

<script setup>
import { computed } from 'vue';
import * as Icon from "lucide-vue-next";
import moment from 'moment';
</script>

<script>
export default {
  components: {
  },
  props: {
    data: { type: Object },
    color: { type: String, default: 'white' },
    textcolor: { type: String, default: 'black' },
  },
  data() {
    return {
      IconComponent: computed(() => Icon[this.icon]),
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>
