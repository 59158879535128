<script>
export default {
  props: {
    enabled: { type: Boolean, default: false },
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  created() {},
  methods: {
    addToCart(product_id, product_name, product_price, product_quantity = 1, currency = 'EUR') {
      window.gtag("event", "add_to_cart", {
          currency: currency,
          value: product_price * product_quantity,
          items: [
            {
              item_id: product_id,
              item_name: product_name,
              price: product_price,
              quantity: product_quantity
            }
          ]
        });
    },
    removeFromCart(product_id, product_name, product_price, product_quantity = 1, currency = 'EUR') {
      window.gtag("event", "remove_from_cart", {
          currency: currency,
          value: product_price * product_quantity,
          items: [
            {
              item_id: product_id,
              item_name: product_name,
              price: product_price,
              quantity: product_quantity
            }
          ]
        });
    },
  },
};
</script>
