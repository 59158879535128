<template>
  <img v-if="!(!integrator || integrator == undefined || integrator == '')" :src="icon" class="rounded-full" >
</template>

<script>
export default {
  props: {
    integrator: { type: String, default: 'eatz' },
  },
  data() {
    return {
      icon: null,
    };
  },
  mounted() {
    switch (this.integrator) {
      case 'eatz':
        this.icon = '/images/integrators/eatz.png';
        break
      case 'uber':
        this.icon = '/images/integrators/ubereats.svg';
        break
      case 'glovo':
        this.icon = '/images/integrators/glovo.png';
        break
      case 'boltfood':
        this.icon = '/images/integrators/boltfood.png';
        break
      case 'zomato':
        this.icon = '/images/integrators/zomato.png';
        break
      default:
        break
      }
    },
  methods: {
    selectFile() {
      this.$refs.inputFile.click();
    },
    handleFileChange(e) {
      this.errors = [];
      // Check if file is selected
      if (e.target.files && e.target.files[0]) {
        // Check if file is valid
        if (this.isFileValid(e.target.files[0])) {
          // Get uploaded file
          const file = e.target.files[0],
            // Get file size
            fileSize = Math.round((file.size / 1024 / 1024) * 100) / 100,
            // Get file extention
            fileExtention = file.name.split(".").pop(),
            // Get file name
            fileName = file.name.split(".").shift(),
            // Check if file is an image
            isImage = ["jpg", "jpeg", "png", "gif"].includes(fileExtention);

          // Load the FileReader API
          let reader = new FileReader();
          // Read uploaded file
          reader.readAsDataURL(file);
          this.$emit("file-uploaded", file, this.name, this.$refs.image);
        } else {
          alert("Invalid file");
        }
      }
    },
    isFileSizeValid(fileSize) {
      if (fileSize <= this.maxSize) {
        console.log("File size is valid");
      } else {
        this.errors.push(`File size should be less than ${this.maxSize} MB`);
      }
    },
    isFileTypeValid(fileExtention) {
      if (this.accept.split(",").includes(fileExtention)) {
        console.log("File type is valid");
      } else {
        this.errors.push(`File type should be ${this.accept}`);
      }
    },
    isFileValid(file) {
      this.isFileSizeValid(Math.round((file.size / 1024 / 1024) * 100) / 100);
      this.isFileTypeValid(file.name.split(".").pop());
      if (this.errors.length === 0) {
        return true;
      } else {
        return false;
      }
    },
    sendDataToParent() {
      this.$emit("file-uploaded", this.file, this.name, this.$refs.image);
    },
  },
};
</script>
