<template>
    <div v-if="showRequest" class="fixed left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-50 py-10 z-40">
      <div class="max-h-full w-full rounded-2xl shadow-2xl max-w-xl overflow-y-auto sm:rounded-2xl bg-white m-5">
          <div class="w-full">
              <div class="m-8 my-10 max-w-[300px] mx-auto">
                  <div class="mb-8 text-center justify-center">
                      <p class="text-gray-600">Deseja receber notificações?
                      </p>
                  </div>
                  <div class="space-y-4">
                      <button class="p-3 bg-black rounded-full text-white w-full font-semibold" @click="requestPermission">Permitir notifications</button>
                      <button class="p-3 bg-white border rounded-full w-full font-semibold" @click="notAcceptNotification">Não</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script setup>
import { initializeApp } from 'firebase/app'
import {getMessaging, getToken, onMessage } from 'firebase/messaging'
</script>

<script>
export default {
  components: {
  },
  props: {    
    user: { type: Object },
  },
  data() {
    return {
      showRequest : false,
      tokenRegistred : false,
      config : {
        apiKey: "AIzaSyCT9f82ToyHoAII_n4z-nn0hK_MFRpwVHc",
        authDomain: "eatz--app.firebaseapp.com",
        projectId: "eatz--app",
        storageBucket: "eatz--app.appspot.com",
        messagingSenderId: "337302425238",
        appId: "1:337302425238:web:209ff62352fcdd716c3cb7",
        measurementId: "G-5NL0YVZPMS"
      },
    };
  },
  mounted() {
    if (("Notification" in window) && Notification.permission !== "granted" && Notification.permission !== "denied") {
      if(localStorage.getItem("notificationAnswered") != 'true'){
        this.showRequest = true;
      }
    }
  },
  methods: {
    notAcceptNotification(){
      localStorage.setItem("notificationAnswered", 'true');
      this.showRequest = false;
    },
    requestPermission() {
      this.showRequest = false;
      const firebase = initializeApp(this.config);
      
      const messaging = getMessaging(firebase);

      getToken(messaging, {vapidKey: 'BIQjEOTpw-v5gEIsqnuTY1ACZNQvt57-1VvxXFjxdavexWV9TLJxpbWcwrKYYleXCQUEWLzIaWihY9otv9OkPgo'}).then((token) => {

        console.log('token', token);

        this.tokenRegistred = this.user?.device_tokens.findIndex(device_token => device_token.token === token) >= 0;

        console.log('tokenRegistred', this.tokenRegistred);
        console.log(token, this.user);

        if(!this.tokenRegistred){
          axios.post("/admin/savePushToken",{
              _method:"POST",
              token
          }).then(({data})=>{
              console.log(data)
              localStorage.setItem("notificationAnswered", 'true');
          }).catch(({response:{data}})=>{
              console.error(data)
          })
        }

        }).catch(function (err) {
            console.log(`Token Error :: ${err}`);
        });


        onMessage(messaging, (payload) => {
          console.log('onMessaging', JSON.stringify(payload));
          new Audio('/audios/new_order.mp3').play();
          new Notification(payload.notification.title, { body: payload.notification.body});
        });
      },
    },
  };
</script>
