<template>

  <div class="p-4 shadow rounded-2xl bg-clip-border break-words bg-white">
    <div class="flex flex-wrap -mx-3">
      <div class="flex-none w-2/3 max-w-full px-2">
        <div>
          <p class="mb-0 font-sans font-semibold leading-normal text-sm">{{title}}</p>
          <h5 class="mb-0 font-bold">
            {{ data }}
          </h5>
        </div>
      </div>
      <div class="w-4/12 max-w-full px-2 ml-auto text-right flex-0">
        <div class="inline-block w-8 h-8 text-center rounded-lg shadow-soft-2xl relative">
          <Icon name="Euro" class="text-lg relative top-3.5 text-blue" :size="25" />
          <component
            :is="IconComponent"
            :size="size"
            :color="color"
          />
        </div>
      </div>
    </div>
  </div>


</template>

<script setup>
import { computed } from 'vue';
import * as Icon from "lucide-vue-next";
</script>

<script>
export default {
  components: {
  },
  props: {
    title: { type: String },
    size: { type: Number, default: 25 },
    color: { type: String, default: 'black' },
    data: "",
    icon: { type: String },
  },
  data() {
    return {
      IconComponent: computed(() => Icon[this.icon]),
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>
