<template>
  <div @click="selectFile">

    <img class="md:bg-cover bg-center bg-no-repeat bg-cover rounded-lg h-full"
    ref="image"
    v-bind:src="file.url" />

    <input
      ref="inputFile"
      type="file"
      name=""
      id=""
      @change="handleFileChange($event)"
      class="hidden"
    />
  </div>
</template>

  <script>
export default {
  name: "FileUpload",
  props: {
    store_id: { type: String, default: null },
    product: { type: Object, default: null },
    name: { type: String, default: "" },
    prefix: { type: String, default: "admin" },
    maxSize: {
      type: Number,
      default: 5,
      required: true,
    },
    accept: {
      type: String,
      default: "image/*",
    },
  },
  data() {
    return {
      errors: [],
      isLoading: false,
      uploadReady: true,
      file: {
        name: "",
        size: 0,
        type: "",
        fileExtention: "",
        url: this.product && this.product.image_url != '' && this.product.image_url != null ? this.product.image_url : ("/"+this.prefix+"/getimage/" + this.name + "/" + (this.store_id ?? "")),
        isImage: false,
        isUploaded: false,
      },
    };
  },
  mounted() {},
  methods: {
    selectFile() {
      this.$refs.inputFile.click();
    },
    handleFileChange(e) {
      this.errors = [];
      // Check if file is selected
      if (e.target.files && e.target.files[0]) {
        // Check if file is valid
        if (this.isFileValid(e.target.files[0])) {
          // Get uploaded file
          const file = e.target.files[0],
            // Get file size
            fileSize = Math.round((file.size / 1024 / 1024) * 100) / 100,
            // Get file extention
            fileExtention = file.name.split(".").pop(),
            // Get file name
            fileName = file.name.split(".").shift(),
            // Check if file is an image
            isImage = ["jpg", "jpeg", "png", "gif"].includes(fileExtention);

          // Load the FileReader API
          let reader = new FileReader();
          // Read uploaded file
          reader.readAsDataURL(file);
          this.$emit("file-uploaded", file, this.name, this.product?.id, this.$refs.image);
        } else {
          alert("Invalid file");
        }
      }
    },
    isFileSizeValid(fileSize) {
      if (fileSize <= this.maxSize) {
        console.log("File size is valid");
      } else {
        this.errors.push(`File size should be less than ${this.maxSize} MB`);
      }
    },
    isFileTypeValid(fileExtention) {
      if (this.accept.split(",").includes(fileExtention)) {
        console.log("File type is valid");
      } else {
        this.errors.push(`File type should be ${this.accept}`);
      }
    },
    isFileValid(file) {
      this.isFileSizeValid(Math.round((file.size / 1024 / 1024) * 100) / 100);
      this.isFileTypeValid(file.name.split(".").pop());
      if (this.errors.length === 0) {
        return true;
      } else {
        return false;
      }
    },
    sendDataToParent() {
      this.$emit("file-uploaded", this.file, this.name, this.product_id, this.$refs.image);
    },
    reload() {
      this.$refs.image.src = this.$refs.image.src.split('?')[0] + "?" + new Date().getTime();
    },
  },
};
</script>
