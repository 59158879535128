<template>
  <div v-if="popupOpened"
    class="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none"
    id="modal-user"
  >
    <div class="absolute bg-black bg-opacity-80 inset-0 z-0"></div>
    <div
      class="w-auto p-5 relative mx-auto my-auto rounded-xl shadow-lg bg-white"
    >
      <!--content-->
      <div>
        <Icon.X
          @click="closePopup"
          class="cursor-pointer absolute top-1 right-1"
        />
        <!--body-->
        <div class="text-center grid grid-cols-1 gap-4 p-5 flex-auto justify-center min-h-60">
          <div class="-auto">
            <Vue3Lottie animationLink="/images/lottie/pause_new_orders.json" :height="100" :width="100"/>
            <br/>
            Infelizmente não estamos recebendo novos pedidos no momento, por favor, tente novamente mais tarde!
            <br/><br/>
            Obrigado
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import * as Icon from "lucide-vue-next";
</script>

<script>
export default {
  props: {
    paused: { type: Boolean, default: false },
  },
  data() {
    return {
      popupOpened: this.paused,
    };
  },
  mounted() {
    },
  methods: {
    closePopup() {
      this.popupOpened = false;
    },
  },
};
</script>
